import React from "react";
import loadable from "@loadable/component";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SafeHandsImg from "../images/safe_hands_img.png"

const Banner = loadable(() => import("../components/Banner/Banner"))
const GoogleReviews = loadable(() => import("../components/GoogleReviews/GoogleReviews"))
const WhatDoYouNeed = loadable(() => import("../components/WhatDoYouNeed/WhatDoYouNeed"))
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const WhyChoose = loadable(() => import("../components/WhyChoose/WhyChoose"))
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"))
const RecentProperties = loadable(() => import("../components/RecentProperties/RecentProperties"))
const FeaturedProperties = loadable(() => import("../components/RecentProperties/FeaturedProperties"))
const SafeHands = loadable(() => import("../components/SafeHands/SafeHands"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))
const AccordionModule = loadable(() => import("../components/AccordionModule/AccordionModule"))
const NewsSlider = loadable(() => import("../components/NewsSlider/NewsSlider"))
const LandingDetail = loadable(() => import("../components/LandingDetail/LandingDetail"))

const LandingPage = ({ data }) => {
  const PageData = data?.strapiPage

  return (
    <Layout classname={PageData?.custom_css_classname} popularSearch={PageData?.select_popular_search?.title}>

      <Banner
        showBreadCrumb
        breadcrumbData={PageData.choose_menu[0]}
        pagename={PageData.title}
        {...PageData.banner}
        title={PageData.banner?.banner_title}
        id={PageData.strapi_id}
        imagetransforms={PageData.imagetransforms}
        btnPrimaryIconClass="icon-banner-valuation"
        btnPrimaryLightIconClass="icon-valuation-home"
        ggfx_results={PageData?.ggfx_results}
      />

      {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_badge" && <GoogleReviews />}
            {module.strapi_component === "page-modules.faq" && <>{module.show_sidebar ? <LandingDetail {...module} /> : <AccordionModule {...module} bg_theme="primary_light" /> }</>}
            {module.strapi_component === "page-modules.services-tiles" && <WhatDoYouNeed id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} />}
            {module.strapi_component === "page-modules.image-and-content" && module.image_alignment === "left" ? <TileBlock id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} /> : module.strapi_component === "page-modules.image-and-content" && <WhyChoose id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} /> }
            {module.strapi_component === "page-modules.statistics" && <><Container>
              <Row>
                <Col>
                  <div className="border-line"></div>
                </Col>
              </Row>
            </Container>

              <StatsModule {...module} />
            </>
            }
            {module.strapi_component === "page-modules.global-module" && module.select_module === "news_slider" && <NewsSlider {...module} showDividerLine/>}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_sold" && <RecentProperties {...module} type="sold"/>}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_let" && <RecentProperties {...module} type="let" />}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && <FeaturedProperties {...module} />}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_slider" && <>
              <div className="border-line"></div><SafeHands {...module} Img={SafeHandsImg} /></>}
            {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
          </>
        )
      }
      )}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}

export default LandingPage


export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                  ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                  ...ImageAndContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                  ...PlainContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_TILES {
                  ...ServicesTilesFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
                  ...StatsFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_FAQ {
                  ...FAQFragment
              }
        }
    }
  }
`